import {Component, OnInit} from '@angular/core';
import {toggleSlidePoint} from '../../globalFunction';

declare var Parallax: any;

@Component({
  selector: 'one-reason',
  templateUrl: './one-reason.component.html',
  styleUrls: ['./one-reason.component.sass']
})
export class OneReasonComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
    toggleSlidePoint();
    const oneReasonScene = document.getElementById('one-reason__scene');
    const parallax = new Parallax(oneReasonScene, {
      pointerEvents: true,
      relativeInput: true,
      hoverOnly: true,
      invertY: false,
      invertX: false,
      selector: '.one-reason__scene-element',
    });
  }
}
