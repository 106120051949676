<header></header>
<main-screen class="check-js show"></main-screen>
<one-reason class="check-js hide"></one-reason>
<two-reason class="check-js hide"></two-reason>
<three-reason class="check-js hide"></three-reason>
<four-reason class="check-js hide"></four-reason>
<five-reason class="check-js hide"></five-reason>
<six-reason class="check-js hide"></six-reason>
<seven-reason class="check-js hide"></seven-reason>
<eight-reason class="check-js hide"></eight-reason>
<nine-reason class="check-js hide"></nine-reason>
<ten-reason class="check-js hide"></ten-reason>
<hashtag class="hide"></hashtag>
<toggle-screen></toggle-screen>
<toggle-slide class="hide"></toggle-slide>
<arrows class="hide"></arrows>
