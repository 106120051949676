import {Component, OnInit} from '@angular/core';

declare var Parallax: any;

@Component({
  selector: 'two-reason',
  templateUrl: './two-reason.component.html',
  styleUrls: ['./two-reason.component.sass']
})
export class TwoReasonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const twoReasonScene = document.getElementById('two-reason__scene');
    const parallax = new Parallax(twoReasonScene, {
      pointerEvents: true,
      relativeInput: true,
      hoverOnly: true,
      invertY: false,
      invertX: false,
      selector: '.two-reason__snow',
    });
  }

}
