import {Component, OnInit} from '@angular/core';
import {toggleScreen} from '../../globalFunction';

declare var Parallax: any;
@Component({
  selector: 'main-screen',
  templateUrl: './main-screen.component.html',
  styleUrls: ['./main-screen.component.sass']
})
export class MainScreenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    document.querySelector('#main-scene').addEventListener('click', () => {
      toggleScreen();
    });
    document.querySelector('.arrow-mobile').addEventListener('click', () => {
      toggleScreen();
    });
    const mainScreenScene = document.getElementById('main-scene');
    const parallax = new Parallax(mainScreenScene, {
      pointerEvents: true,
      relativeInput: true,
      hoverOnly: false,
      invertY: false,
      invertX: false,
      selector: '.main-screen__svg, .main-screen__text',
    });
  }

}
