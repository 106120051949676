  <a class="header__menu-logo" (click)="pageReload()"></a>
  <button class="header__menu-button" (click)="openMenu()">
    <svg class="header__menu-svg" width="57px" height="57px" viewBox="0 0 57 57">
      <rect class="header__menu-rect" x="2" y="2" fill="none" width="53px" height="53px" rx="0" ry="0"></rect>
    </svg>
  </button>

<div class="header__menu-container">

  <div class="header__menu-list">
    <div data-slide="one-reason" class="header__menu-item">
      <i class="header__menu-i">01</i>
      <span class="header__menu-span">Попробовать югорские деликатесы</span>
      <svg class="header__menu-hover" width="548px" height="240px" viewBox="0 0 548 240">
        <rect class="header__hover-border" x="2" y="2" fill="none" width="53px" height="53px" rx="0" ry="0"></rect>
      </svg>
    </div>

    <div data-slide="two-reason" class="header__menu-item">
      <i class="header__menu-i">02</i>
      <span class="header__menu-span">окунуться<br>в зимнюю<br>сказку</span>
      <svg class="header__menu-hover" width="548px" height="240px" viewBox="0 0 548 240">
        <rect class="header__hover-border" x="2" y="2" fill="none" width="53px" height="53px" rx="0" ry="0"></rect>
      </svg>
    </div>

    <div data-slide="three-reason" class="header__menu-item">
      <i class="header__menu-i">03</i>
      <span class="header__menu-span">побывать<br>на стойбище<br>ханты и манси</span>
      <svg class="header__menu-hover" width="548px" height="240px" viewBox="0 0 548 240">
        <rect class="header__hover-border" x="2" y="2" fill="none" width="53px" height="53px" rx="0" ry="0"></rect>
      </svg>
    </div>

    <div data-slide="four-reason" class="header__menu-item">
      <i class="header__menu-i">04</i>
      <span class="header__menu-span">принять участие<br>в праздниках<br>народов Севера</span>
      <svg class="header__menu-hover" width="548px" height="240px" viewBox="0 0 548 240">
        <rect class="header__hover-border" x="2" y="2" fill="none" width="53px" height="53px" rx="0" ry="0"></rect>
      </svg>
    </div>

    <div data-slide="five-reason" class="header__menu-item">
      <i class="header__menu-i">05</i>
      <span class="header__menu-span">покорить самую<br>высокую гору<br>Уральского хребта</span>
      <svg class="header__menu-hover" width="548px" height="240px" viewBox="0 0 548 240">
        <rect class="header__hover-border" x="2" y="2" fill="none" width="53px" height="53px" rx="0" ry="0"></rect>
      </svg>
    </div>

    <div data-slide="six-reason" class="header__menu-item">
      <i class="header__menu-i">06</i>
      <span class="header__menu-span">увидеть первую<br>в России плавучую<br>часовню-маяк</span>
      <svg class="header__menu-hover" width="548px" height="240px" viewBox="0 0 548 240">
        <rect class="header__hover-border" x="2" y="2" fill="none" width="53px" height="53px" rx="0" ry="0"></rect>
      </svg>
    </div>

    <div data-slide="seven-reason" class="header__menu-item">
      <i class="header__menu-i">07</i>
      <span class="header__menu-span">посмотреть, как нефть качают</span>
      <svg class="header__menu-hover" width="548px" height="240px" viewBox="0 0 548 240">
        <rect class="header__hover-border" x="2" y="2" fill="none" width="53px" height="53px" rx="0" ry="0"></rect>
      </svg>
    </div>

    <div data-slide="eight-reason" class="header__menu-item">
      <i class="header__menu-i">08</i>
      <span class="header__menu-span">очутиться среди обитателей каменного века</span>
      <svg class="header__menu-hover" width="548px" height="240px" viewBox="0 0 548 240">
        <rect class="header__hover-border" x="2" y="2" fill="none" width="53px" height="53px" rx="0" ry="0"></rect>
      </svg>
    </div>

    <div data-slide="nine-reason" class="header__menu-item">
      <i class="header__menu-i">09</i>
      <span class="header__menu-span">просто порыбачить</span>
      <svg class="header__menu-hover" width="548px" height="240px" viewBox="0 0 548 240">
        <rect class="header__hover-border" x="2" y="2" fill="none" width="53px" height="53px" rx="0" ry="0"></rect>
      </svg>
    </div>

    <div data-slide="ten-reason" class="header__menu-item">
      <i class="header__menu-i">10</i>
      <span class="header__menu-span">посетить один<br>из самых больших в стране океанариумов</span>
      <svg class="header__menu-hover" width="548px" height="240px" viewBox="0 0 548 240">
        <rect class="header__hover-border" x="2" y="2" fill="none" width="53px" height="53px" rx="0" ry="0"></rect>
      </svg>
    </div>

    <a href="https://investugra.ru/" data-slide="invest" class="header__menu-item">
      <span class="header__menu-span">Инвестиционный<br>портал Югры</span>
      <svg class="header__menu-hover" width="548px" height="240px" viewBox="0 0 548 240">
        <rect class="header__hover-border" x="2" y="2" fill="none" width="53px" height="53px" rx="0" ry="0"></rect>
      </svg>
    </a>

  </div>
</div>
