<div class="prew">
  <svg width="77" height="16" viewBox="0 0 77 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.292893 7.29289C-0.097631 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928933C7.68054 0.538408 7.04738 0.538408 6.65685 0.928933L0.292893 7.29289ZM77 7.00001L1 7L1 9L77 9.00001L77 7.00001Z" fill="white"/>
  </svg>
</div>
<div class="next">
  <svg class="arrow" width="77" height="16" viewBox="0 0 77 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M76.7071 8.70711C77.0976 8.31659 77.0976 7.68342 76.7071 7.2929L70.3431 0.928938C69.9526 0.538414 69.3195 0.538414 68.9289 0.928938C68.5384 1.31946 68.5384 1.95263 68.9289 2.34315L74.5858 8.00001L68.9289 13.6569C68.5384 14.0474 68.5384 14.6805 68.9289 15.0711C69.3195 15.4616 69.9526 15.4616 70.3431 15.0711L76.7071 8.70711ZM-8.74228e-08 9L76 9.00001L76 7.00001L8.74228e-08 7L-8.74228e-08 9Z" fill="white"/>
  </svg>
  <svg class="replay" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.78534 0.191131V1.76802C7.78534 1.95973 7.63133 2.13538 7.44249 2.16846C3.2285 2.90843 0.015625 6.59261 0.015625 11.0153C0.015625 15.9695 4.0461 19.9999 9.00024 19.9999C13.9545 19.9999 17.9849 15.9695 17.9849 11.0153C17.9849 10.536 17.5964 10.1475 17.1171 10.1475C16.6378 10.1475 16.2493 10.536 16.2493 11.0153C16.2493 15.0125 12.9974 18.2644 9.00024 18.2644C5.00308 18.2644 1.75122 15.0125 1.75122 11.0153C1.75122 7.55228 4.19278 4.6503 7.44453 3.93638C7.63175 3.89532 7.78531 4.02558 7.78531 4.21722V5.60584C7.78531 5.79755 7.90566 5.85461 8.05408 5.73334L11.2539 3.1181C11.4023 2.99672 11.4023 2.80013 11.2539 2.67875L8.05416 0.0637378C7.9057 -0.0576428 7.78534 -0.000469125 7.78534 0.191131Z" fill="white"/>
  </svg>
</div>
