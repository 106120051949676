import {Component, OnInit} from '@angular/core';

declare var Parallax: any;

@Component({
  selector: 'eight-reason',
  templateUrl: './eight-reason.component.html',
  styleUrls: ['./eight-reason.component.sass']
})
export class EightReasonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const eightReasonScene = document.getElementById('eight-reason__scene');
    const parallax = new Parallax(eightReasonScene, {
      pointerEvents: true,
      relativeInput: true,
      invertY: false,
      invertX: false,
      selector: '.eight-reason__img',
    });
  }

}
