import {Component, OnInit} from '@angular/core';

declare var Parallax: any;

@Component({
  selector: 'six-reason',
  templateUrl: './six-reason.component.html',
  styleUrls: ['./six-reason.component.sass']
})
export class SixReasonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const sixReasonScene = document.getElementById('six-reason__scene');
    const parallax = new Parallax(sixReasonScene, {
      pointerEvents: true,
      relativeInput: true,
      limitY: 0,
      invertY: false,
      invertX: false,
      selector: '.six-reason__img',
    });
    const sixReasonScene1 = document.getElementById('six-reason__scene');
    const parallax1 = new Parallax(sixReasonScene1, {
      pointerEvents: true,
      relativeInput: true,
      limitY: 0,
      invertY: true,
      invertX: true,
      selector: '.six-reason__img.img-3',
    });
  }

}
