<div class="nine-reason__background fon fon-gradient fon-1" style="opacity: 1"></div>
<div class="nine-reason__background fon fon-gradient fon-2" style="opacity: 0"></div>
<div class="nine-reason__background fon fon-gradient fon-3" style="opacity: 0"></div>

<div class="floor-description container-animation">
  <h2 class="floor-description__floor">09</h2>
  <p class="hashtag">#VisitUgra</p>
  <h2 class="floor-description__description">просто порыбачить</h2>
  <div class="container-slide-description">
    <h3 class="slide__slide-description description-1" style="opacity: 1">Хотя в реках и озерах Югры водится немало видов рыб, в том числе и ценных,
      многие рыболовы приезжают сюда за трофейной щукой. Рыбных мест у нас – уйма!</h3>
    <h3 class="slide__slide-description description-2" style="opacity: 0">На туристских базах созданы условия для ловли не только традиционным способом,
      как это издревле делают ханты и манси, но и с использованием современных снастей. Рыбакам на заметку: сезон рыбной
      ловли в Югре длится практически круглый год.</h3>
    <h3 class="slide__slide-description description-3" style="opacity: 0">Если повезет, можно поймать экземпляр весом 20 кг и более.</h3>
  </div>
</div>
<div class="nine-reason__container container-animation">
  <div class="point point-1" data-index="1">
    <i class="point__circle"></i>
    <span class="point__description">охота<br>за щукой</span>
  </div>
  <div class="point point-2" data-index="2">
    <i class="point__circle"></i>
    <span class="point__description">туристические<br>базы</span>
  </div>
  <div class="point point-3" data-index="3">
    <i class="point__circle"></i>
    <span class="point__description">большая<br>рыбалка</span>
  </div>
  <div class="slide slide-1" style="opacity: 1">
    <div id="nine-reason__scene" class="reason-scene" data-relative-input="true">
      <div class="nine-reason__img img-0" data-depth="0.05"></div>
      <div class="nine-reason__img img-1" data-depth="0.1"></div>
      <div class="nine-reason__img img-2" data-depth="0.2"></div>
      <div class="nine-reason__img img-3" data-depth="0.2"></div>
      <div class="nine-reason__img img-4" data-depth="0.1"></div>
    </div>
  </div>
  <div class="slide slide-2" style="opacity: 0">
  <div class="slide slide-3" style="opacity: 0">
  </div>
</div>
