<div class="five-reason__background fon fon-gradient fon-1" style="opacity: 1"></div>
<div class="five-reason__background fon fon-gradient fon-2" style="opacity: 0">
  <video loop autoplay>
    <source src="assets/img/five-reason/five-reason.mp4" type='video/mp4'>
  </video>
</div>

<div class="floor-description container-animation">
  <h2 class="floor-description__floor">05</h2>
  <p class="hashtag">#VisitUgra</p>
  <h2 class="floor-description__description">покорить самую высокую гору<br>Уральского хребта</h2>
  <div class="container-slide-description">
    <h3 class="slide__slide-description description-1" style="opacity: 1">Югра – это высоко! Горы Приполярного Урала – жемчужины, рассыпанные вдоль
      северо-западной границы Югры. В Березовском районе находится наивысшая точка – гора Народная высотой 1 894,5
      м.</h3>
    <h3 class="slide__slide-description description-2" style="opacity: 0">Зимние снегоходные экспедиции или летние восхождения на Народную или Неройку,
      посещение заброшенной штольни на уникальном месторождении кварца и горного хрусталя Додо, рыбалка на Зейских
      озерах – это далеко не полный перечень активностей, которые ждут вас на Приполярном Урале.</h3>
  </div>
</div>
<div class="five-reason__container container-animation">
  <div class="point point-1" data-index="1">
    <i class="point__circle"></i>
    <span class="point__description">Югра – это высоко!</span>
  </div>
  <div class="point point-2" data-index="2">
    <i class="point__circle"></i>
    <span class="point__description">пешие<br>экспедиции</span>
  </div>
  <div class="slide slide-1" style="opacity: 1">
    <div id="five-reason__scene" class="reason-scene" data-relative-input="true">
      <div class="five-reason__img img-1" data-depth="0.05"></div>
      <div class="five-reason__img img-2" data-depth="0.05"></div>
    </div>
  </div>
  <div class="slide slide-2" style="opacity: 0">
  </div>
</div>
