<div class="eight-reason__background fon fon-gradient fon-1" style="opacity: 1"></div>
<div class="eight-reason__background fon fon-gradient fon-2" style="opacity: 0"></div>

<div class="floor-description container-animation">
  <h2 class="floor-description__floor">08</h2>
  <p class="hashtag">#VisitUgra</p>
  <h2 class="floor-description__description">очутиться среди обитателей<br>каменного века</h2>
  <div class="container-slide-description">
    <h3 class="slide__slide-description description-1" style="opacity: 1">Когда-то Югру населяли мамонты, шерстистые носороги и пещерные львы. </h3>
    <h3 class="slide__slide-description description-2" style="opacity: 0">Недалеко от Ханты-Мансийска ученые-археологи проводят раскопки и по сей день
      обнаруживают хорошо сохранившиеся останки былых гигантов. Сейчас их можно увидеть в «Музее природы и человека» или
      <br>в культурно-туристическом комплексе «Археопарк». Нужно селфи с бронзовым 8-метровым мамонтом или первобытным
      человеком? Тогда вам в Ханты-Мансийск.</h3>
  </div>
</div>
<div class="eight-reason__container container-animation">
  <div class="point point-1" data-index="1">
    <i class="point__circle"></i>
    <span class="point__description">Древние<br>обитатели Югры</span>
  </div>
  <div class="point point-2" data-index="2">
    <i class="point__circle"></i>
    <span class="point__description">Музей природы<br>и человека</span>
  </div>
  <div class="slide slide-1" style="opacity: 1">
    <canvas id="eight-reason__canvas"></canvas>
    <div id="eight-reason__scene" class="reason-scene" data-relative-input="true">
      <div class="eight-reason__img img-1" data-depth="0.1"></div>
      <div class="eight-reason__img img-2" data-depth="0.1"></div>
    </div>
  </div>
  <div class="slide slide-2" style="opacity: 0">
  </div>
</div>
