<div class="six-reason__background fon fon-gradient fon-1" style="opacity: 1"></div>

<div class="floor-description container-animation">
  <h2 class="floor-description__floor">06</h2>
  <p class="hashtag">#VisitUgra</p>
  <h2 class="floor-description__description">увидеть первую в России<br>плавучую часовню-маяк</h2>
  <div class="container-slide-description">
    <h3 class="slide__slide-description description-1" style="opacity: 1">Недалеко от Ханты-Мансийска есть место силы: здесь сливаются две великие
      сибирские реки – Обь<br>и Иртыш. Эта локация завоевала первое место в фестивале-конкурсе «Диво России» и была
      признана «Волшебным местом России». На слиянии Оби и Иртыша установлена часовня-маяк.<br>Высота конструкции – 8
      метров, а вес – 10 тонн! В России нет аналогов подобной часовни-маяка. В 2013 году ее освятил Святейший Патриарх
      Московский и всея Руси Кирилл.</h3>
  </div>
</div>
<div class="six-reason__container container-animation">
  <div class="point point-1" data-index="1" style="top: 40%; left: 50%">
    <i class="point__circle"></i>
    <span class="point__description">Сделано<br>в Югре</span>
  </div>
  <div class="slide slide-1" style="opacity: 1">
    <canvas id="six-reason__canvas"></canvas>
    <div id="six-reason__scene" class="reason-scene" data-relative-input="true">
      <div class="six-reason__img img-1" data-depth="0.02"></div>
      <div class="six-reason__img img-2" data-depth="0.08"></div>
      <div class="six-reason__img img-3" data-depth="0.08"></div>
    </div>
  </div>
</div>
