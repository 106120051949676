import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {MainScreenComponent} from './components/main-screen/main-screen.component';
import {HeaderComponent} from './components/header/header.component';
import {ToggleScreenComponent} from './components/toggle-screen/toggle-screen.component';
import {OneReasonComponent} from './components/one-reason/one-reason.component';
import {HashtagComponent} from './components/hashtag/hashtag.component';
import {ToggleSlideComponent} from './components/toggle-slide/toggle-slide.component';
import {TwoReasonComponent} from './components/two-reason/two-reason.component';
import {ThreeReasonComponent} from './components/three-reason/three-reason.component';
import {FourReasonComponent} from './components/four-reason/four-reason.component';
import {FiveReasonComponent} from './components/five-reason/five-reason.component';
import {SixReasonComponent} from './components/six-reason/six-reason.component';
import {SevenReasonComponent} from './components/seven-reason/seven-reason.component';
import {EightReasonComponent} from './components/eight-reason/eight-reason.component';
import {NineReasonComponent} from './components/nine-reason/nine-reason.component';
import {TenReasonComponent} from './components/ten-reason/ten-reason.component';
import {ArrowsComponent} from './components/arrows/arrows.component';

@NgModule({
  declarations: [
    AppComponent,
    MainScreenComponent,
    HeaderComponent,
    ToggleScreenComponent,
    OneReasonComponent,
    HashtagComponent,
    ToggleSlideComponent,
    TwoReasonComponent,
    ThreeReasonComponent,
    FourReasonComponent,
    FiveReasonComponent,
    SixReasonComponent,
    SevenReasonComponent,
    EightReasonComponent,
    NineReasonComponent,
    TenReasonComponent,
    ArrowsComponent,
  ],
  imports: [
    BrowserModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
