import {Component, OnInit} from '@angular/core';

declare var Parallax: any;

@Component({
  selector: 'five-reason',
  templateUrl: './five-reason.component.html',
  styleUrls: ['./five-reason.component.sass']
})
export class FiveReasonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const fiveReasonScene = document.getElementById('five-reason__scene');
    const parallax = new Parallax(fiveReasonScene, {
      pointerEvents: true,
      relativeInput: true,
      limitY: 0,
      invertY: false,
      invertX: false,
      selector: '.five-reason__img',
    });
  }

}
