<div class="ten-reason__background fon fon-gradient fon-1" style="opacity: 1"></div>
<div class="ten-reason__background fon fon-gradient fon-2" style="opacity: 0"></div>

<div class="floor-description container-animation">
  <h2 class="floor-description__floor">10</h2>
  <p class="hashtag">#VisitUgra</p>
  <h2 class="floor-description__description">посетить один из самых больших<br>в стране океанариумов</h2>
  <div class="container-slide-description">
    <h3 class="slide__slide-description description-1" style="opacity: 1">Океанариум «Акватика» находится в г. Когалыме. Гигантский «танк» (так ихтиологи
      называют аквариумы) объемом 3,5 млн литров обжили 18 акул, 3 гигантские мурены, кровожадные пираньи, крылатые
      скаты,<br>рыба-шар, крокодилы, черепахи… </h3>
    <h3 class="slide__slide-description description-2" style="opacity: 0">Но не только экзотическими обитателями манит гостей «Акватика». Здесь можно
      совершить погружение<br>на глубину до 4 метров в главном морском аквариуме и поплавать вместе с акулами! Или заняться
      серфингом на аттракционе «Флоурайдер».</h3>
  </div>
</div>
<div class="ten-reason__container container-animation">
  <div class="point point-1" data-index="1">
    <i class="point__circle"></i>
    <span class="point__description">Океанариум<br>«Акватика»</span>
  </div>
  <div class="point point-2" data-index="2">
    <i class="point__circle"></i>
    <span class="point__description">подводные<br>жители</span>
  </div>
  <div class="slide slide-1" style="opacity: 1">
    <canvas id="ten-reason__canvas"></canvas>
    <div id="ten-reason__scene" class="reason-scene" data-relative-input="true">
      <div class="ten-reason__img img-1" data-depth="0.1"></div>
      <div class="ten-reason__img img-2" data-depth="0.15"></div>
      <div class="ten-reason__img img-3" data-depth="0.1"></div>
      <div class="ten-reason__img img-4" data-depth="0.15"></div>
    </div>
  </div>
  <div class="slide slide-2" style="opacity: 0">
  </div>
</div>
