import {Component, OnInit} from '@angular/core';

declare var Parallax: any;

@Component({
  selector: 'three-reason',
  templateUrl: './three-reason.component.html',
  styleUrls: ['./three-reason.component.sass']
})
export class ThreeReasonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const threeReasonScene = document.getElementById('three-reason__scene');
    const parallax = new Parallax(threeReasonScene, {
      pointerEvents: true,
      relativeInput: true,
      limitY: 0,
      invertY: false,
      invertX: false,
      selector: '.three-reason__img',
    });
  }
}
