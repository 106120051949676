import {Component, OnInit} from '@angular/core';
import {toggleSlide} from '../../globalFunction';

@Component({
  selector: 'toggle-slide',
  templateUrl: './toggle-slide.component.html',
  styleUrls: ['./toggle-slide.component.sass']
})
export class ToggleSlideComponent implements OnInit {
  constructor() {
  }

  ngOnInit()
    :
    void {
    toggleSlide();
  }
}
