<div class="four-reason__background fon fon-gradient fon-1" style="opacity: 1"></div>
<div class="four-reason__background fon fon-gradient fon-2" style="opacity: 0">
  <video loop autoplay>
    <source src="assets/img/four-reason/four-reason-2.mp4" type='video/mp4'>
  </video>
</div>
<div class="four-reason__background fon fon-gradient fon-3" style="opacity: 0">
  <video loop autoplay>
    <source src="assets/img/four-reason/four-reason-3.mp4" type='video/mp4'>
  </video>
</div>

<div class="floor-description container-animation">
  <h2 class="floor-description__floor">04</h2>
  <p class="hashtag">#VisitUgra</p>
  <h2 class="floor-description__description">принять участие<br>в праздниках народов Севера</h2>
  <div class="container-slide-description">
    <h3 class="slide__slide-description description-1" style="opacity: 1">Югра – это не только вкусно, но и очень зрелищно.</h3>
    <h3 class="slide__slide-description description-2" style="opacity: 0">День оленевода, Вороний день, День обласа – яркие события, приправленные
      местным колоритом.</h3>
    <h3 class="slide__slide-description description-3" style="opacity: 0">Ежегодно недалеко от Нефтеюганска устраивается турнир на Кубок губернатора
      округа по гребле<br>на обласах – лодках-долбленках, изготовляемых из цельных кусков кедра, осины или тополя длиной 4
      м<br>и более. Хотите научиться управлять такой лодкой или освоить искусство метания тынзяна на хорей? Югра ждет.</h3>
  </div>
</div>
<div class="four-reason__container container-animation">
  <div class="point point-1" data-index="1">
    <i class="point__circle"></i>
    <span class="point__description">Югра – это<br>Зрелищно</span>
  </div>
  <div class="point point-2" data-index="2">
    <i class="point__circle"></i>
    <span class="point__description">яркие<br>события</span>
  </div>
  <div class="point point-3" data-index="3">
    <i class="point__circle"></i>
    <span class="point__description">турнир по гребле<br>на обласах</span>
  </div>
  <div class="slide slide-1" style="opacity: 1">
    <div id="four-reason__scene" class="reason-scene" data-relative-input="true">
      <div class="four-reason__img img-1" data-depth="0.1"></div>
      <div class="four-reason__img img-2" data-depth="0.1"></div>
    </div>
  </div>
  <div class="slide slide-2" style="opacity: 0">
  </div>
  <div class="slide slide-3" style="opacity: 0">
  </div>
</div>
