<div class="three-reason__background fon fon-gradient fon-1" style="opacity: 1"></div>
<div class="three-reason__background fon fon-gradient fon-2" style="opacity: 0">
  <video loop autoplay>
    <source src="assets/img/three-reason/three-reason-video.mp4" type='video/mp4'>
  </video>
</div>
<div class="three-reason__background fon fon-gradient fon-3" style="opacity: 0"></div>

<div class="floor-description container-animation">
  <h2 class="floor-description__floor">03</h2>
  <p class="hashtag">#VisitUgra</p>
  <h2 class="floor-description__description">побывать на стойбище<br>ханты и манси</h2>
  <div class="container-slide-description">
    <h3 class="slide__slide-description description-1" style="opacity: 1">Югра – это колоритно! В многонациональной Югре издревле живут коренные
      малочисленные народы. </h3>
    <h3 class="slide__slide-description description-2" style="opacity: 0">С культурой ханты и манси, их традициями, образом жизни можно познакомиться не
      только в современных музеях Ханты-Мансийска, Нижневартовска, Сургутского района, но и на настоящих
      этностойбищах. </h3>
    <h3 class="slide__slide-description description-3" style="opacity: 0">Здесь вы можете научиться запрягать оленя и управлять нартами, отведать блюда
      традиционной кухни северных народов и даже... пожить в чуме! Богатая культура, уникальный быт обских угров
      привлекают и манят путешественников, туристов, ученых.</h3>
  </div>
</div>
<div class="three-reason__container container-animation">
  <div class="point point-1" data-index="1">
    <i class="point__circle"></i>
    <span class="point__description">Югра – это<br>колоритно</span>
  </div>
  <div class="point point-2" data-index="2">
    <i class="point__circle"></i>
    <span class="point__description">культура<br>ханты и манси</span>
  </div>
  <div class="point point-3" data-index="3" style="top: 40%; left: 70%">
    <i class="point__circle"></i>
    <span class="point__description">быт<br>обских угров</span>
  </div>
  <div class="slide slide-1" style="opacity: 1">
    <canvas id="three-reason__canvas"></canvas>
    <div id="three-reason__scene" class="reason-scene" data-relative-input="true">
      <div class="three-reason__img img-1" data-depth="0.1"></div>
      <div class="three-reason__img img-2" data-depth="0.1"></div>
    </div>
  </div>
  <div class="slide slide-2" style="opacity: 0">
  </div>
  <div class="slide slide-3" style="opacity: 0">
  </div>
</div>
