import {Component, OnInit} from '@angular/core';
import {toggleScreen} from '../../globalFunction';

@Component({
  selector: 'toggle-screen',
  templateUrl: './toggle-screen.component.html',
  styleUrls: ['./toggle-screen.component.sass']
})
export class ToggleScreenComponent implements OnInit {

  ngOnInit(): void {
    document.querySelector('.toggle-screen__container').addEventListener('click', () => {
      toggleScreen();
    });
  }
}
