<div class="one-reason__background fon fon-gradient fon-1" style="opacity: 1"></div>
<div class="one-reason__background fon fon-gradient fon-2" style="opacity: 0"></div>
<div class="one-reason__background fon fon-gradient fon-3" style="opacity: 0"></div>

<div class="floor-description container-animation">
  <h2 class="floor-description__floor">01</h2>
  <p class="hashtag">#VisitUgra</p>
  <h2 class="floor-description__description">Попробовать<br>югорские деликатесы</h2>
  <div class="container-slide-description">
    <h3 class="slide__slide-description description-1" style="opacity: 1">Разнообразие животного и растительного мира
      породило настоящие
      гастрономические бренды Югры: сосьвинская сельдь, кышиковский чебак, кондинский гриб, кедровые орехи, брусника и
      княженика – уникальная продукция, выпускаемая под маркой «Сделано в Югре».</h3>
    <h3 class="slide__slide-description description-2" style="opacity: 0">Кстати, сосьвинская сельдь (или тугун) – это
      единственная в мире
      пресноводная сельдь, которая водится<br>в реке Северная Сосьва (Березовский район) и является югорским
      деликатесом.</h3>
    <h3 class="slide__slide-description description-3" style="opacity: 0">Ежегодно у нас проходит гастрономический
      фестиваль «Попробуй Югру на вкус!», <br> где можно отведать все югорские вкусности.</h3>
  </div>
</div>
<div class="one-reason__container container-animation">
  <div class="point point-1" data-index="1">
    <i class="point__circle"></i>
    <span class="point__description">Сделано<br>в Югре</span>
  </div>
  <div class="point point-2" data-index="2">
    <i class="point__circle"></i>
    <span class="point__description">Сосьвинская<br>селедка</span>
  </div>
  <div class="point point-3" data-index="3">
    <i class="point__circle"></i>
    <span class="point__description">Попробуй Югру<br>на Вкус</span>
  </div>
  <div class="slide slide-1" style="opacity: 1">
    <canvas id="one-reason__canvas"></canvas>
    <div id="one-reason__scene" class="reason-scene" data-relative-input="true">
      <div class="one-reason__scene-element one-reason__element-1" data-depth="0.1"></div>
      <div class="one-reason__scene-element one-reason__element-2" data-depth="0.4"></div>
      <div class="one-reason__scene-element one-reason__element-3" data-depth="0.1"></div>
      <div class="one-reason__scene-element one-reason__element-4" data-depth="0.4"></div>
    </div>
  </div>
  <div class="slide slide-2" style="opacity: 0">
  </div>
  <div class="slide slide-3" style="opacity: 0">
  </div>
</div>
