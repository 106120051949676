<div class="toggle-slide__container">
  <button class="toggle-slide__button active" data-slide="one-reason"></button>
  <button class="toggle-slide__button" data-slide="two-reason"></button>
  <button class="toggle-slide__button" data-slide="three-reason"></button>
  <button class="toggle-slide__button" data-slide="four-reason"></button>
  <button class="toggle-slide__button" data-slide="five-reason"></button>
  <button class="toggle-slide__button" data-slide="six-reason"></button>
  <button class="toggle-slide__button" data-slide="seven-reason"></button>
  <button class="toggle-slide__button" data-slide="eight-reason"></button>
  <button class="toggle-slide__button" data-slide="nine-reason"></button>
  <button class="toggle-slide__button" data-slide="ten-reason"></button>
</div>
