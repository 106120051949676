<div class="two-reason__background fon fon-gradient fon-1" style="opacity: 1"></div>
<div class="two-reason__background fon fon-gradient fon-2" style="opacity: 0"></div>
<div class="two-reason__background fon fon-gradient fon-3" style="opacity: 0"></div>

<div class="floor-description container-animation">
  <h2 class="floor-description__floor">02</h2>
  <p class="hashtag">#VisitUgra</p>
  <h2 class="floor-description__description">окунуться<br>в зимнюю сказку</h2>
  <div class="container-slide-description">
    <h3 class="slide__slide-description description-1" style="opacity: 1">Югра – это настоящая сибирская зима!</h3>
    <h3 class="slide__slide-description description-2" style="opacity: 0">В 2017 году Ханты-Мансийск был «Новогодней столицей России». С тех пор каждый
      новый год в столице округа работает единственная в стране «Резиденция Елки», отрывается Чумовая улица, где вместо
      домов чумы, а вместо дорог и тротуаров – трассы для катания на собачьих и оленьих упряжках. Кстати, местного Деда
      Мороза зовут Ищки-Ики.</h3>
    <h3 class="slide__slide-description description-3" style="opacity: 0">Катание на горных или беговых лыжах, сноуборде, коньках, снегоходах или
      прогулки по заснеженному лесу… – все зимние активности к вашим услугам. А после них можно понежиться в бассейне с
      теплой минеральной водой под открытым небом…</h3>
  </div>
</div>
<div class="two-reason__container container-animation">
  <div class="point point-1" data-index="1">
    <i class="point__circle"></i>
    <span class="point__description">Югра -<br>зимняя сказка</span>
  </div>
  <div class="point point-2" data-index="2">
    <i class="point__circle"></i>
    <span class="point__description">Ханты-Мансийск –<br>новогодняя столица Сибири</span>
  </div>
  <div class="point point-3" data-index="3">
    <i class="point__circle"></i>
    <span class="point__description">Зимние<br>активности</span>
  </div>
  <div class="slide slide-1" style="opacity: 1">
    <canvas id="two-reason__canvas"></canvas>
    <div id="two-reason__scene" class="reason-scene" data-relative-input="true">
      <div class="two-reason__snow two-reason__snow-1" data-depth="0.02"></div>
      <div class="two-reason__snow two-reason__snow-2" data-depth="0.02"></div>
    </div>
  </div>
  <div class="slide slide-2" style="opacity: 0">
  </div>
  <div class="slide slide-3" style="opacity: 0">
  </div>
</div>
