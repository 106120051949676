import {Component, OnInit} from '@angular/core';
import {toggleSlideMenu} from '../../globalFunction';

declare var $: any;

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})

export class HeaderComponent implements OnInit {
  checkOpenMenu: boolean = false;
  checkShowMenuElement;

  openMenu() {
    this.checkOpenMenu = !this.checkOpenMenu;
    let button: HTMLElement = document.querySelector('.header__menu-button');
    if (!$('.header__menu-container').is(':visible')) {
      this.checkShowMenuElement = setInterval(() => {
        if (document.querySelector('.header__menu-container') !== null){
          button.classList.add('header__menu-button-open');
          document.querySelector('.header__menu-container').classList.add('header__menu-container-open');
          setTimeout(function(){
            document.querySelector('.header__menu-container').classList.add('opacity');
          },50);
          clearInterval(this.checkShowMenuElement);
        }
      }, 100);
    } else {
      button.classList.remove('header__menu-button-open');
      document.querySelector('.header__menu-container').classList.remove('opacity');
      setTimeout(function(){
        document.querySelector('.header__menu-container').classList.remove('header__menu-container-open');
      },350);
    }
  }

  pageReload() {
      document.location.reload();
  }

  ngOnInit(): void {
    toggleSlideMenu();
  }

}


