import {Component, OnInit} from '@angular/core';

declare var Parallax: any;

@Component({
  selector: 'seven-reason',
  templateUrl: './seven-reason.component.html',
  styleUrls: ['./seven-reason.component.sass']
})
export class SevenReasonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const sevenReasonScene = document.getElementById('seven-reason__scene');
    const parallax = new Parallax(sevenReasonScene, {
      pointerEvents: true,
      relativeInput: true,
      limitY: 0,
      invertY: false,
      invertX: false,
      selector: '.seven-reason__img',
    });
  }

}
