<div class="seven-reason__background fon fon-gradient fon-1" style="opacity: 1"></div>
<div class="seven-reason__background fon fon-gradient fon-2" style="opacity: 0"></div>

<div class="floor-description container-animation">
  <h2 class="floor-description__floor">07</h2>
  <p class="hashtag">#VisitUgra</p>
  <h2 class="floor-description__description">посмотреть, как нефть качают</h2>
  <div class="container-slide-description">
    <h3 class="slide__slide-description description-1" style="opacity: 1">А знаете ли вы, что в Югре добывают почти половину всей российской нефти и
      более 5% всех мировых запасов? Для сравнения – это больше, чем добывает в год вся Канада, Кувейт или КНР! </h3>
    <h3 class="slide__slide-description description-2" style="opacity: 0">Хотите побывать на настоящем месторождении? В ходе ойл-тура вы посетите первую
      разведочную скважину «Р-1» Самотлорского месторождения, узнаете о богатой нефтяной истории освоения Западной
      Сибири. Увезете с собой сувенир – капельку югорской нефти.</h3>
  </div>
</div>
<div class="seven-reason__container container-animation">
  <div class="point point-1" data-index="1">
    <i class="point__circle"></i>
    <span class="point__description">добыча<br>нефти</span>
  </div>
  <div class="point point-2" data-index="2">
    <i class="point__circle"></i>
    <span class="point__description">настоящее<br>месторождение</span>
  </div>
  <div class="slide slide-1" style="opacity: 1">
    <div id="seven-reason__scene" class="reason-scene" data-relative-input="true">
      <div class="seven-reason__img img-2" data-depth="0.1"></div>
    </div>
  </div>
  <div class="slide slide-2" style="opacity: 0">
  </div>
</div>
