<div class="main-screen__background fon fon-1"></div>
<div class="main-screen__container container-animation">
  <div id="main-scene" class="main-screen__content">
    <svg data-depth="0.1" class="main-screen__svg" width="464" height="322" viewBox="0 0 464 322" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M68.2044 315H152.244V4.79998H90.2044L0.00437517 30.32L16.2844 96.32L68.2044 84.44V315ZM320.269 321.16C408.269 321.16 463.269 248.12 463.269 161C463.269 73 408.709 0.83998 321.149 0.83998C233.589 0.83998 178.149 73.88 178.149 161C178.149 249 232.269 321.16 320.269 321.16ZM321.149 245.48C286.829 245.48 263.949 210.28 263.949 161C263.949 110.84 286.389 76.52 320.269 76.52C354.149 76.52 377.469 112.16 377.469 161C377.469 210.72 355.469 245.48 321.149 245.48Z"
        fill="url(#paint0_linear)"/>
      <linearGradient id="paint0_linear" x1="67.3242" y1="164.898" x2="441.012" y2="164.898"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.421875" stop-color="white"/>
        <stop offset="1" stop-color="white" stop-opacity="0.2"/>
      </linearGradient>
    </svg>
    <div data-depth="0.1" class="main-screen__text">
      <p class="main-screen__p">причин<br>посетить<br>югру</p>
      <span class="main-screen__span">#VisitUgra</span>
    </div>
  </div>
  <div class="main-screen__scene">
    <div class="main-screen__cloud main-screen__cloud-1"></div>
    <div class="main-screen__cloud main-screen__cloud-2"></div>
    <div class="main-screen__cloud main-screen__cloud-3"></div>
    <div class="main-screen__cloud main-screen__cloud-4" ></div>
    <svg class="arrow-mobile" width="51" height="8" viewBox="0 0 51 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M50.3536 4.35356C50.5488 4.1583 50.5488 3.84171 50.3536 3.64645L47.1716 0.46447C46.9763 0.269208 46.6597 0.269208 46.4645 0.46447C46.2692 0.659732 46.2692 0.976315 46.4645 1.17158L49.2929 4L46.4645 6.82843C46.2692 7.02369 46.2692 7.34028 46.4645 7.53554C46.6597 7.7308 46.9763 7.7308 47.1716 7.53554L50.3536 4.35356ZM-4.37114e-08 4.5L50 4.5L50 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="white"/>
    </svg>
  </div>
</div>
