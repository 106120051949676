import {Component, OnInit} from '@angular/core';

declare var Parallax: any;

@Component({
  selector: 'four-reason',
  templateUrl: './four-reason.component.html',
  styleUrls: ['./four-reason.component.sass']
})
export class FourReasonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const fourReasonScene = document.getElementById('four-reason__scene');
    const parallax = new Parallax(fourReasonScene, {
      pointerEvents: true,
      relativeInput: true,
      invertY: false,
      invertX: false,
      selector: '.four-reason__img',
    });
  }

}
