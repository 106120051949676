import {Component, OnInit} from '@angular/core';
import {toggleScreen} from '../../globalFunction';

declare var $: any;

@Component({
  selector: 'arrows',
  templateUrl: './arrows.component.html',
  styleUrls: ['./arrows.component.sass']
})
export class ArrowsComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    $('.prew').click(function() {
      var cachesIndex = $('.toggle-slide__button').index($('.toggle-slide__container').find('.active')[0]);
      if (cachesIndex - 1 >= 0) {
        document.querySelector('.next').classList.remove('active');
        const main = $('main').addClass('disable');
        var htmlColSlideBack: HTMLDivElement;
        const caches = $('.toggle-slide__container').find('.active').attr('data-slide');
        $('.toggle-slide__button').removeClass('active');
        $($('.toggle-slide__button')[cachesIndex - 1]).addClass('active');
        const nextSlide = $('.toggle-slide__container').find('.active').attr('data-slide');
        $('.header__menu-item').removeClass('active');
        $('.header__menu-list').find('[data-slide="' + $(this).attr('data-slide') + '"').addClass('active');
        $($('.header__menu-item')[cachesIndex - 1]).addClass('active');
        document.querySelectorAll('' + caches + ' .fon').forEach((element: HTMLDivElement) => {
          if (element.style.display !== 'none') {
            htmlColSlideBack = element;
          }
        });
        $('' + nextSlide + '').removeClass('hide').addClass('hide-slide show');
        $('' + caches + '').addClass('hide-slide');
        $('hashtag').addClass('hide');
        setTimeout(function() {
          $('' + caches + '').removeClass('show');
          const interval1 = setInterval(function() {
            if (htmlColSlideBack.offsetWidth === 0) {
              $('' + nextSlide + '').removeClass('hide-slide');
              $('' + caches + '').removeClass('hide-slide , show').addClass('hide');
              $('hashtag').removeClass('hide');
              main.removeClass('disable');
              document.querySelectorAll('.fon').forEach((element: HTMLDivElement) => {
                if (!element.classList.contains('fon-1')) {
                  element.style.opacity = '0';
                } else {
                  element.style.opacity = '1';
                }
              });
              document.querySelectorAll('.slide__slide-description').forEach((element: HTMLDivElement) => {
                if (!element.classList.contains('description-1')) {
                  element.style.opacity = '0';
                } else {
                  element.style.opacity = '1';
                }
              });
              document.querySelectorAll('.point').forEach((element: HTMLDivElement) => {
                if (element.classList.contains('point-1')) {
                  element.style.opacity = '0';
                  element.classList.add('disable');
                } else {
                  element.style.opacity = '1';
                  element.classList.remove('disable');
                }
              });
              document.querySelectorAll('.slide').forEach((element: HTMLDivElement) => {
                if (!element.classList.contains('slide-1')) {
                  element.style.opacity = '0';
                } else {
                  element.style.opacity = '1';
                }
              });
              clearInterval(interval1);
            }
          }, 50);
        }, 300);
      } else {
        toggleScreen();
        reloadSlide();
      }
    });
    $('.next').click(function() {
      var htmlColSlideBack: HTMLDivElement;
      var cachesIndex = $('.toggle-slide__button').index($('.toggle-slide__container').find('.active')[0]);
      if (cachesIndex + 1 !== 10) {
        document.querySelector('.next').classList.remove('active');
        const main = $('main').addClass('disable');
        const caches = $('.toggle-slide__container').find('.active').attr('data-slide');
        $('.toggle-slide__button').removeClass('active');
        $($('.toggle-slide__button')[cachesIndex + 1]).addClass('active');
        const nextSlide = $('.toggle-slide__container').find('.active').attr('data-slide');
        $('.header__menu-item').removeClass('active');
        $($('.header__menu-item')[cachesIndex + 1]).addClass('active');
        document.querySelectorAll('' + nextSlide + ' .fon').forEach((element: HTMLDivElement) => {
          if (element.style.display !== 'none') {
            htmlColSlideBack = element;
          }
        });
        $('' + caches + '').addClass('hide-slide');
        $('' + nextSlide + '').removeClass('hide').addClass('hide-slide');
        $('hashtag').addClass('hide');
        setTimeout(function() {
          $('' + nextSlide + '').addClass('show');
          const interval = setInterval(function() {
            if (window.innerWidth === htmlColSlideBack.offsetWidth) {
              $('' + nextSlide + '').removeClass('hide-slide');
              $('' + caches + '').removeClass('hide-slide , show').addClass('hide');
              main.removeClass('disable');
              $('hashtag').removeClass('hide');
              reloadSlide();
              clearInterval(interval);
            }
          }, 50);
        }, 300);
      } else {
        toggleScreen();
        reloadSlide();
        $('.toggle-slide__button').removeClass('active');
        $($('.toggle-slide__button')[0]).addClass('active');
      }
      if (cachesIndex + 2 === 10) {
        document.querySelector('.next').classList.add('active');
      }
    });
  }
}
function reloadSlide() {
  setTimeout(function() {
  document.querySelectorAll('.fon').forEach((element: HTMLDivElement) => {
    if (!element.classList.contains('fon-1')) {
      element.style.opacity = '0';
    } else {
      element.style.opacity = '1';
    }
  });
  document.querySelectorAll('.slide__slide-description').forEach((element: HTMLDivElement) => {
    if (!element.classList.contains('description-1')) {
      element.style.opacity = '0';
    } else {
      element.style.opacity = '1';
    }
  });
  document.querySelectorAll('.point').forEach((element: HTMLDivElement) => {
    if (element.classList.contains('point-1')) {
      element.style.opacity = '0';
      element.classList.add('disable');
    } else {
      element.style.opacity = '1';
      element.classList.remove('disable');
    }
  });
  document.querySelectorAll('.slide').forEach((element: HTMLDivElement) => {
    if (!element.classList.contains('slide-1')) {
      element.style.opacity = '0';
    } else {
      element.style.opacity = '1';
    }
  });
  }, 450);
}
