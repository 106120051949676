declare var $: any;

export function toggleSlidePoint() {
  $('.point-1').each(function() {
    this.style.opacity = '0';
  });
  $('.point-1').each(function() {
    this.classList.add('disable');
  });
  $('.point').click(function() {
    const ancestors = $(this).closest('.check-js')[0].localName;
    const index = $(this).attr('data-index');
    $('' + ancestors + ' .point').each(function() {
      this.style.opacity = '1';
      this.classList.remove('disable');
    });
    $('' + ancestors + ' .fon').each(function() {
      if (this.style.opacity === '1') {
        this.style.cssText = 'opacity: 0;';
      }
    });
    $('' + ancestors + ' .slide').each(function() {
      if (this.style.opacity === '1') {
        this.style.cssText = 'opacity: 0;';
      }
    });
    $('' + ancestors + ' .slide__slide-description').each(function() {
      if (this.style.opacity === '1') {
        this.style.cssText = 'opacity: 0;';
      }
    });
    this.style.opacity = '0';
    this.classList.add('disable');
    $('' + ancestors + ' .fon-' + index + '')[0].style.cssText = 'opacity: 1';
    $('' + ancestors + ' .slide-' + index + '')[0].style.cssText = 'opacity: 1';
    $('' + ancestors + ' .description-' + index + '')[0].style.cssText = 'opacity: 1';
  });
}

export function toggleSlide() {
  $('.toggle-slide__button').click(function() {
    const main = $('main').addClass('disable');
    var htmlColSlideBack: HTMLDivElement;
    const caches = $('.toggle-slide__container').find('.active').attr('data-slide');
    var cachesIndex = $('.toggle-slide__button').index($('.toggle-slide__container').find('.active')[0]);
    $('.toggle-slide__button').removeClass('active');
    $(this).addClass('active');
    const nextSlide = $('.toggle-slide__container').find('.active').attr('data-slide');
    const nextSlideIndex = $('.toggle-slide__button').index(this);
    $('.header__menu-item').removeClass('active');
    $('.header__menu-list').find('[data-slide="' + $(this).attr('data-slide') + '"').addClass('active');
    if (nextSlideIndex > cachesIndex) {
      document.querySelectorAll('' + nextSlide + ' .fon').forEach((element: HTMLDivElement) => {
        if (element.style.display !== 'none') {
          htmlColSlideBack = element;
        }
      });
      if ($('.header__menu-item[data-slide="ten-reason"]').hasClass('active')) {
        document.querySelector('.next').classList.add('active');
      }
      $('' + caches + '').addClass('hide-slide');
      $('' + nextSlide + '').removeClass('hide').addClass('hide-slide');
      $('hashtag').addClass('hide');
      setTimeout(function() {
        $('' + nextSlide + '').addClass('show');
        const interval = setInterval(function() {
          if (window.innerWidth === htmlColSlideBack.offsetWidth) {
            $('' + nextSlide + '').removeClass('hide-slide');
            $('' + caches + '').removeClass('hide-slide , show').addClass('hide');
            main.removeClass('disable');
            $('hashtag').removeClass('hide');
            reloadSlide();
            clearInterval(interval);
          }
        }, 50);
      }, 300);
    } else {
      document.querySelector('.next').classList.remove('active');
      document.querySelectorAll('' + caches + ' .fon').forEach((element: HTMLDivElement) => {
        if (element.style.display !== 'none') {
          htmlColSlideBack = element;
        }
      });
      $('' + nextSlide + '').removeClass('hide').addClass('hide-slide show');
      $('' + caches + '').addClass('hide-slide');
      $('hashtag').addClass('hide');
      setTimeout(function() {
        $('' + caches + '').removeClass('show');
        const interval1 = setInterval(function() {
          if (htmlColSlideBack.offsetWidth === 0) {
            $('' + nextSlide + '').removeClass('hide-slide');
            $('' + caches + '').removeClass('hide-slide , show').addClass('hide');
            $('hashtag').removeClass('hide');
            main.removeClass('disable');
            reloadSlide();
            clearInterval(interval1);
          }
        }, 50);
      }, 300);
    }
  });
}


export function toggleSlideMenu() {
  $('.header__menu-item').click(function() {
    const main = $('main').addClass('disable');
    var htmlColSlideBack: HTMLDivElement;
    var caches = $('.header__menu-list').find('.active').attr('data-slide');
    var cachesIndex = $('.header__menu-item').index($('.header__menu-list').find('.active')[0]);
    $('.header__menu-item').removeClass('active');
    $(this).addClass('active');
    $('.toggle-slide__button').removeClass('active');
    $('.toggle-slide__container').find('[data-slide="' + $(this).attr('data-slide') + '"').addClass('active');
    const nextSlide = $('.header__menu-list').find('.active').attr('data-slide');
    const nextSlideIndex = $('.header__menu-item').index(this);
    const toggleScreen = document.querySelector('toggle-screen');
    const toggleSlide = document.querySelector('toggle-slide');
    const arrows = document.querySelector('arrows');
    document.querySelector('.header__menu-button').classList.remove('header__menu-button-open');
    document.querySelector('.header__menu-container').classList.remove('opacity');
    setTimeout(function() {
      document.querySelector('.header__menu-container').classList.remove('header__menu-container-open');
    }, 350);
    if ($('main-screen').hasClass('show')) {
      cachesIndex = -1;
    }
    if (nextSlideIndex > cachesIndex) {
      document.querySelectorAll('' + nextSlide + ' .fon').forEach((element: HTMLDivElement) => {
        if (element.style.display !== 'none') {
          htmlColSlideBack = element;
        }
      });
      toggleScreen.classList.add('toggle-screen__true');
      toggleSlide.classList.remove('hide');
      $('' + caches + '').addClass('hide-slide');
      $('' + nextSlide + '').removeClass('hide').addClass('hide-slide');
      $('hashtag').addClass('hide');
      if ($('.header__menu-item[data-slide="ten-reason"]').hasClass('active')) {
        document.querySelector('.next').classList.add('active');
      }
      setTimeout(function() {
        $('' + nextSlide + '').addClass('show');
        const interval = setInterval(function() {
          if (window.innerWidth === htmlColSlideBack.offsetWidth) {
            $('' + nextSlide + '').removeClass('hide-slide');
            if (cachesIndex !== -1) {
              $('' + caches + '').removeClass('hide-slide , show').addClass('hide');
            } else {
              $('main-screen').addClass('hide').removeClass('show');
            }
            main.removeClass('disable');
            $('hashtag').removeClass('hide');
            arrows.classList.remove('hide');
            reloadSlide();
            clearInterval(interval);
          }
        }, 50);
      }, 300);
    } else {
      document.querySelector('.next').classList.remove('active');
      document.querySelectorAll('' + caches + ' .fon').forEach((element: HTMLDivElement) => {
        if (element.style.display !== 'none') {
          htmlColSlideBack = element;
        }
      });
      $('' + nextSlide + '').removeClass('hide').addClass('hide-slide show');
      $('' + caches + '').addClass('hide-slide');
      $('hashtag').addClass('hide');
      setTimeout(function() {
        $('' + caches + '').removeClass('show');
        const interval1 = setInterval(function() {
          if (htmlColSlideBack.offsetWidth === 0) {
            $('' + nextSlide + '').removeClass('hide-slide');
            $('' + caches + '').removeClass('hide-slide , show').addClass('hide');
            $('hashtag').removeClass('hide');
            main.removeClass('disable');
            reloadSlide();
            clearInterval(interval1);
          }
        }, 50);
      }, 300);
    }
  });
}


var check: boolean = true;
var casheScreen: string;

export function toggleScreen() {
  document.querySelectorAll('main')[0].classList.add('disable');
  if (check === true) {
    check = false;
    casheScreen = 'one-reason';
  }
  if (document.querySelectorAll('main-screen.show')[0] === undefined) {
    slideToMainscreen();
  } else {
    casheScreen = 'one-reason';
    mainScreenToSlide();
  }
}

function slideToMainscreen() {
  casheScreen = document.querySelector('.show.check-js').localName;
  const slide = casheScreen;
  const main = document.querySelector('main');
  const toggleScreen = document.querySelector('toggle-screen');
  const toggleSlide = document.querySelector('toggle-slide');
  const hashtag = document.querySelector('hashtag');
  const mainScreen = document.querySelector('main-screen');
  const arrows = document.querySelector('arrows');
  const htmlColSlide = document.querySelector('' + slide + '');
  const thisElement = document.querySelector('' + casheScreen + '');
  var htmlColSlideBack: HTMLDivElement;
  document.querySelectorAll('' + slide + ' .fon').forEach((element: HTMLDivElement) => {
    if (element.style.display !== 'none') {
      htmlColSlideBack = element;
    }
  });
  toggleScreen.classList.remove('toggle-screen__true');
  mainScreen.classList.remove('hide');
  thisElement.classList.add('hide-slide');
  hashtag.classList.add('hide');
  toggleSlide.classList.add('hide');
  arrows.classList.add('hide');
  mainScreen.classList.add('hide-slide', 'show');
  setTimeout(() => {
    htmlColSlide.classList.remove('show');
    const interval = setInterval(() => {
      if (htmlColSlideBack.offsetWidth === 0) {
        mainScreen.classList.remove('hide-slide');
        htmlColSlide.classList.add('hide');
        main.classList.remove('disable');
        $('.header__menu-item.active').removeClass('active');
        clearInterval(interval);
      }
    }, 50);
  }, 300);
}

function mainScreenToSlide() {
  const slide: string = casheScreen;
  const main = document.querySelector('main');
  const toggleScreen = document.querySelector('toggle-screen');
  const toggleSlide = document.querySelector('toggle-slide');
  const hashtag = document.querySelector('hashtag');
  const mainScreen = document.querySelector('main-screen');
  const arrows = document.querySelector('arrows');
  const htmlColSlide = document.querySelector('' + slide + '');
  var htmlColSlideBack: HTMLDivElement;
  document.querySelectorAll('' + slide + ' .fon').forEach((element: HTMLDivElement) => {
    if (element.style.display !== 'none') {
      htmlColSlideBack = element;
    }
  });
  document.querySelector('.next').classList.remove('active');
  mainScreen.classList.add('hide-slide');
  toggleScreen.classList.add('toggle-screen__true');
  htmlColSlide.classList.remove('hide');
  htmlColSlide.classList.add('hide-slide');
  setTimeout(() => {
    htmlColSlide.classList.add('show');
    const interval = setInterval(() => {
      if (window.innerWidth === htmlColSlideBack.offsetWidth) {
        htmlColSlide.classList.remove('hide-slide');
        mainScreen.classList.remove('hide-slide');
        mainScreen.classList.add('hide');
        mainScreen.classList.remove('show');
        hashtag.classList.remove('hide');
        arrows.classList.remove('hide');
        toggleSlide.classList.remove('hide');
        main.classList.remove('disable');
        $($('.header__menu-item')[0]).addClass('active');
        clearInterval(interval);
      }
    }, 50);
  }, 300);
}

$('main').bind('mousewheel', function(e) {
  var div = $("header");
  if (!div.is(e.target) && div.has(e.target).length === 0) {
    if (!$('main').hasClass('disable')) {
      document.querySelector('.next').classList.remove('active');
      const main = $('main');
      if (e.originalEvent.wheelDelta >= 0) {
        if (!$('main-screen').hasClass('show')) {
          var cachesIndex = $('.toggle-slide__button').index($('.toggle-slide__container').find('.active')[0]);
          if (cachesIndex - 1 >= 0) {
            $('main').addClass('disable');
            var htmlColSlideBack: HTMLDivElement;
            const caches = $('.toggle-slide__container').find('.active').attr('data-slide');
            $('.toggle-slide__button').removeClass('active');
            $($('.toggle-slide__button')[cachesIndex - 1]).addClass('active');
            const nextSlide = $('.toggle-slide__container').find('.active').attr('data-slide');
            $('.header__menu-item').removeClass('active');
            $('.header__menu-list').find('[data-slide="' + $(this).attr('data-slide') + '"').addClass('active');
            $($('.header__menu-item')[cachesIndex - 1]).addClass('active');
            document.querySelectorAll('' + caches + ' .fon').forEach((element: HTMLDivElement) => {
              if (element.style.display !== 'none') {
                htmlColSlideBack = element;
              }
            });
            $('' + nextSlide + '').removeClass('hide').addClass('hide-slide show');
            $('' + caches + '').addClass('hide-slide');
            $('hashtag').addClass('hide');
            setTimeout(function() {
              $('' + caches + '').removeClass('show');
              const interval1 = setInterval(function() {
                if (htmlColSlideBack.offsetWidth === 0) {
                  $('' + nextSlide + '').removeClass('hide-slide');
                  $('' + caches + '').removeClass('hide-slide , show').addClass('hide');
                  $('hashtag').removeClass('hide');
                  main.removeClass('disable');
                  reloadSlide();
                  clearInterval(interval1);
                }
              }, 50);
            }, 300);
          } else {
            toggleScreen();
            reloadSlide();
            $('.toggle-slide__button').removeClass('active');
            $($('.toggle-slide__button')[0]).addClass('active');
          }
        }
      } else {
        if (!$('main-screen').hasClass('show')) {
          var htmlColSlideBack: HTMLDivElement;
          var cachesIndex = $('.toggle-slide__button').index($('.toggle-slide__container').find('.active')[0]);
          if (cachesIndex + 2 === 10) {
            document.querySelector('.next').classList.add('active');
          }
          if (cachesIndex + 1 !== 10) {
            $('main').addClass('disable');
            const caches = $('.toggle-slide__container').find('.active').attr('data-slide');
            $('.toggle-slide__button').removeClass('active');
            $($('.toggle-slide__button')[cachesIndex + 1]).addClass('active');
            const nextSlide = $('.toggle-slide__container').find('.active').attr('data-slide');
            $('.header__menu-item').removeClass('active');
            $($('.header__menu-item')[cachesIndex + 1]).addClass('active');
            document.querySelectorAll('' + nextSlide + ' .fon').forEach((element: HTMLDivElement) => {
              if (element.style.display !== 'none') {
                htmlColSlideBack = element;
              }
            });
            $('' + caches + '').addClass('hide-slide');
            $('' + nextSlide + '').removeClass('hide').addClass('hide-slide');
            $('hashtag').addClass('hide');
            setTimeout(function() {
              $('' + nextSlide + '').addClass('show');
              const interval = setInterval(function() {
                if (window.innerWidth === htmlColSlideBack.offsetWidth) {
                  $('' + nextSlide + '').removeClass('hide-slide');
                  $('' + caches + '').removeClass('hide-slide , show').addClass('hide');
                  main.removeClass('disable');
                  $('hashtag').removeClass('hide');
                  reloadSlide();
                  clearInterval(interval);
                }
              }, 50);
            }, 300);
          } else {
            toggleScreen();
            reloadSlide();
            $('.toggle-slide__button').removeClass('active');
            $($('.toggle-slide__button')[0]).addClass('active');
          }
        } else {
          toggleScreen();
          reloadSlide();
          $('.toggle-slide__button').removeClass('active');
          $($('.toggle-slide__button')[0]).addClass('active');
        }
      }
    }
  }
});
$(window).on('swipeleft', function() {
  if (!$('main').hasClass('disable')) {
    document.querySelector('.next').classList.remove('active');
    const main = $('main');
    if (!$('main-screen').hasClass('show')) {
      var htmlColSlideBack: HTMLDivElement;
      var cachesIndex = $('.toggle-slide__button').index($('.toggle-slide__container').find('.active')[0]);
      if (cachesIndex + 2 === 10) {
        document.querySelector('.next').classList.add('active');
      }
      if (cachesIndex + 1 !== 10) {
        $('main').addClass('disable');
        const caches = $('.toggle-slide__container').find('.active').attr('data-slide');
        $('.toggle-slide__button').removeClass('active');
        $($('.toggle-slide__button')[cachesIndex + 1]).addClass('active');
        const nextSlide = $('.toggle-slide__container').find('.active').attr('data-slide');
        $('.header__menu-item').removeClass('active');
        $($('.header__menu-item')[cachesIndex + 1]).addClass('active');
        document.querySelectorAll('' + nextSlide + ' .fon').forEach((element: HTMLDivElement) => {
          if (element.style.display !== 'none') {
            htmlColSlideBack = element;
          }
        });
        $('' + caches + '').addClass('hide-slide');
        $('' + nextSlide + '').removeClass('hide').addClass('hide-slide');
        $('hashtag').addClass('hide');
        setTimeout(function() {
          $('' + nextSlide + '').addClass('show');
          const interval = setInterval(function() {
            if (window.innerWidth === htmlColSlideBack.offsetWidth) {
              $('' + nextSlide + '').removeClass('hide-slide');
              $('' + caches + '').removeClass('hide-slide , show').addClass('hide');
              main.removeClass('disable');
              $('hashtag').removeClass('hide');
              reloadSlide();
              clearInterval(interval);
            }
          }, 50);
        }, 300);
      } else {
        toggleScreen();
        reloadSlide();
        $('.toggle-slide__button').removeClass('active');
        $($('.toggle-slide__button')[0]).addClass('active');
      }
    } else {
      toggleScreen();
      reloadSlide();
      $('.toggle-slide__button').removeClass('active');
      $($('.toggle-slide__button')[0]).addClass('active');
    }
  }
});
$(window).on('swiperight', function() {
  if (!$('main').hasClass('disable')) {
    const main = $('main');
    document.querySelector('.next').classList.remove('active');
    if (!$('main-screen').hasClass('show')) {
      var cachesIndex = $('.toggle-slide__button').index($('.toggle-slide__container').find('.active')[0]);
      if (cachesIndex - 1 >= 0) {
        $('main').addClass('disable');
        var htmlColSlideBack: HTMLDivElement;
        const caches = $('.toggle-slide__container').find('.active').attr('data-slide');
        $('.toggle-slide__button').removeClass('active');
        $($('.toggle-slide__button')[cachesIndex - 1]).addClass('active');
        const nextSlide = $('.toggle-slide__container').find('.active').attr('data-slide');
        $('.header__menu-item').removeClass('active');
        $('.header__menu-list').find('[data-slide="' + $(this).attr('data-slide') + '"').addClass('active');
        $($('.header__menu-item')[cachesIndex - 1]).addClass('active');
        document.querySelectorAll('' + caches + ' .fon').forEach((element: HTMLDivElement) => {
          if (element.style.display !== 'none') {
            htmlColSlideBack = element;
          }
        });
        $('' + nextSlide + '').removeClass('hide').addClass('hide-slide show');
        $('' + caches + '').addClass('hide-slide');
        $('hashtag').addClass('hide');
        setTimeout(function() {
          $('' + caches + '').removeClass('show');
          const interval1 = setInterval(function() {
            if (htmlColSlideBack.offsetWidth === 0) {
              $('' + nextSlide + '').removeClass('hide-slide');
              $('' + caches + '').removeClass('hide-slide , show').addClass('hide');
              $('hashtag').removeClass('hide');
              main.removeClass('disable');
              reloadSlide();
              clearInterval(interval1);
            }
          }, 50);
        }, 300);
      } else {
        toggleScreen();
        reloadSlide();
        $('.toggle-slide__button').removeClass('active');
        $($('.toggle-slide__button')[0]).addClass('active');
      }
    }
  }
});

function loader(){
  this.hide = function() {
    document.querySelector('load').classList.add('load-hide');
    setTimeout(function () {
      document.querySelector('load').classList.add('load-none');
  },350);
  };
  this.show = function() {
    setTimeout(function () {
      document.querySelector('load').classList.remove('load-none');
    },50);
    document.querySelector('load').classList.remove('load-hide');
  };
}
let load = new loader();

window.addEventListener('load', function() {
  load.hide();
});


document.addEventListener("DOMContentLoaded", function() {
  if (window.innerWidth < 1121) {
    document.querySelectorAll('video').forEach((element) => {
      element.remove();
    });
    document.querySelectorAll('.point .point__description').forEach((element) => {
      element.textContent = '';
    });
  }
});

function reloadSlide() {
  setTimeout(function() {
  document.querySelectorAll('.fon').forEach((element: HTMLDivElement) => {
    if (!element.classList.contains('fon-1')) {
      element.style.opacity = '0';
    } else {
      element.style.opacity = '1';
    }
  });
  document.querySelectorAll('.slide__slide-description').forEach((element: HTMLDivElement) => {
    if (!element.classList.contains('description-1')) {
      element.style.opacity = '0';
    } else {
      element.style.opacity = '1';
    }
  });
  document.querySelectorAll('.point').forEach((element: HTMLDivElement) => {
    if (element.classList.contains('point-1')) {
      element.style.opacity = '0';
      element.classList.add('disable');
    } else {
      element.style.opacity = '1';
      element.classList.remove('disable');
    }
  });
  document.querySelectorAll('.slide').forEach((element: HTMLDivElement) => {
    if (!element.classList.contains('slide-1')) {
      element.style.opacity = '0';
    } else {
      element.style.opacity = '1';
    }
  });
  }, 450);
}
document.addEventListener("DOMContentLoaded", function() {
  if (window.innerWidth > 1121) {
    $('head').append('<link rel="preload" href="assets/img/one-reason/one-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/one-reason/left-back.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/one-reason/left-front.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/one-reason/right-back.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/one-reason/right-front.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/one-reason/one-reason-2.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/one-reason/one-reason-3.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/two-reason/two-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/two-reason/паралакс.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/two-reason/asdas.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/two-reason/two-reason-2.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/two-reason/two-reason-3.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/three-reason/three-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/three-reason/Привал-10.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/three-reason/Шаман-2.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/three-reason/three-reason-2.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/three-reason/three-reason-3.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/four-reason/four-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/four-reason/Group%20498.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/four-reason/Group%20499.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/four-reason/four-reason-2.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/four-reason/four-reason-3.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/five-reason/five-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/five-reason/Group%201.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/five-reason/Group%200.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/five-reason/five-reason-2.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/six-reason/six-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/six-reason/часовня.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/six-reason/яхта%201.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/six-reason/ston.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/seven-reason/seven-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/seven-reason/лестница%201.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/seven-reason/трубы.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/seven-reason/seven-reason-2.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/eight-reason/eight-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/eight-reason/облако-1.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/eight-reason/облако-2.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/eight-reason/eight-reason-2.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/nine-reason/nine-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/nine-reason/рыбалка.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/nine-reason/tall-grass-png-44175%201.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/nine-reason/tall-grass-png-44175%202.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/nine-reason/tall-grass-png-44175%203.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/nine-reason/tall-grass-png-44175%204.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/nine-reason/nine-reason-2.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/nine-reason/nine-reason-3.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/ten-reason/ten-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/ten-reason/камни_1.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/ten-reason/камни_2.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/ten-reason/камни_3.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/ten-reason/камни_4.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/ten-reason/ten-reason-2.jpg" as="image">');
  } else {
    $('head').append('' +
      '  <link rel="preload" href="assets/img/one-reason/one-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/two-reason/two-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/three-reason/three-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/four-reason/four-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/five-reason/five-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/six-reason/six-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/six-reason/часовня.png" as="image">\n' +
      '  <link rel="preload" href="assets/img/seven-reason/seven-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/eight-reason/eight-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/nine-reason/nine-reason-1.jpg" as="image">\n' +
      '  <link rel="preload" href="assets/img/ten-reason/ten-reason-1.jpg" as="image">');
  }
});

$(document).ready(function() {
  setTimeout(function() {
    for(var i = 0; $('video').length > i; i++){
      $('video')[i].play();
    }
  },7000);
});
