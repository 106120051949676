import {Component, OnInit} from '@angular/core';

declare var Parallax: any;

@Component({
  selector: 'ten-reason',
  templateUrl: './ten-reason.component.html',
  styleUrls: ['./ten-reason.component.sass']
})
export class TenReasonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const tenReasonScene = document.getElementById('ten-reason__scene');
    const parallax = new Parallax(tenReasonScene, {
      pointerEvents: true,
      relativeInput: true,
      limitY: 0,
      invertY: false,
      invertX: false,
      selector: '.ten-reason__img',
    });
  }

}
